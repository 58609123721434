import { CircularProgress } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import useGetAllEuroChallengeRequests from "../../../hooks/api/useGetAllEuroChallengeRequests";
import useDeleteEuroChallengeRequest from "../../../hooks/api/useDeleteEuroChallengeRequest";
import DeleteModal from "../../../components/deleteModal";
import useAcceptEuroChallengeRequest from "../../../hooks/api/useAcceptEuroChallengeRequest";
import useRejectEuroChallengeRequest from "../../../hooks/api/useRejectEuroChallengeRequest";

import classes from "./../style.module.css";

interface RowType {
  address: string;
  city: string;
  create_at: string;
  email: string;
  f_name: string;
  l_name: string;
  mobile: string;
  eurochallenge_request__id: string;
  team_name: string;
  zip_code: string;
  status: string;
}

interface DeleteOptions {
  showConfirmDelete: boolean;
  id: string;
}
interface AcceptOptions {
  showConfirmAccept: boolean;
  id: string;
}
interface RejectOptions {
  showConfirmReject: boolean;
  id: string;
}

const AllEuroChallengeRequestsTable = () => {
  const [loadingList, setLoadingList] = useState<(string | number)[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 5,
  });

  const [deleteOptions, setDeleteOptions] = useState<DeleteOptions>({
    showConfirmDelete: false,
    id: "",
  });
  const [acceptOptions, setAcceptOptions] = useState<AcceptOptions>({
    showConfirmAccept: false,
    id: "",
  });
  const [rejectOptions, setRejectOptions] = useState<RejectOptions>({
    showConfirmReject: false,
    id: "",
  });

  const showDeleteModal = (params: GridRowParams) => {
    setDeleteOptions({
      id: `${params.id}`,
      showConfirmDelete: true,
    });
  };
  const showAcceptModal = (params: GridRowParams) => {
    setAcceptOptions({
      id: `${params.id}`,
      showConfirmAccept: true,
    });
  };
  const showRejectModal = (params: GridRowParams) => {
    setRejectOptions({
      id: `${params.id}`,
      showConfirmReject: true,
    });
  };

  const deleteRequestAction = async (params: DeleteOptions) => {
    setLoadingList((d) => [...d, params.id]);
    await deleteEuroChallengeRequest({ requestId: `${params.id} ` });
    setLoadingList((d) => d.filter((l) => l !== params.id));
  };
  const acceptRequestAction = async (params: AcceptOptions) => {
    setLoadingList((d) => [...d, params.id]);
    await acceptEuroChallengeRequest({ requestId: `${params.id} ` });
    setLoadingList((d) => d.filter((l) => l !== params.id));
  };
  const rejectRequestAction = async (params: RejectOptions) => {
    setLoadingList((d) => [...d, params.id]);
    await rejectEuroChallengeRequest({ requestId: `${params.id} ` });
    setLoadingList((d) => d.filter((l) => l !== params.id));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "f_name",
      headerName: "First Name",
      width: 160,
    },
    {
      field: "l_name",
      headerName: "Last Name",
      width: 160,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 160,
    },
    { field: "city", headerName: "City", width: 160 },
    { field: "address", headerName: "Address", width: 200 },
    {
      field: "create_at",
      headerName: "Create at",
      width: 160,
    },
    {
      field: "team_name",
      headerName: "Team Name",
      width: 160,
    },
    {
      field: "zip_code",
      headerName: "Zip Code",
      width: 100,
    },
    {
      field: "actions",
      type: "actions",
      width: 300,
      getActions: (params: GridRowParams) => [
        <button
          disabled={deleteLoading && loadingList.includes(params.id)}
          onClick={() => showDeleteModal(params)}
          className="border-2 w-full min-w-[80px] px-2 py-2 rounded-md border-red"
        >
          {deleteLoading && loadingList.includes(params.id) ? (
            <CircularProgress
              sx={{ width: "15px !important", height: "15px !important" }}
            />
          ) : (
            "Delete"
          )}
        </button>,
        <button
          disabled={acceptLoading && loadingList.includes(params.id)}
          onClick={() => showAcceptModal(params)}
          className="border-2 w-full min-w-[80px] px-2 py-2 rounded-md border-lime-700"
        >
          {acceptLoading && loadingList.includes(params.id) ? (
            <CircularProgress
              sx={{ width: "15px !important", height: "15px !important" }}
            />
          ) : (
            "Accept"
          )}
        </button>,
        <button
          disabled={rejectLoading && loadingList.includes(params.id)}
          onClick={() => showRejectModal(params)}
          className="border-2 w-full min-w-[80px] px-2 py-2 rounded-md border-yellow"
        >
          {rejectLoading && loadingList.includes(params.id) ? (
            <CircularProgress
              sx={{ width: "15px !important", height: "15px !important" }}
            />
          ) : (
            "Reject"
          )}
        </button>,
      ],
    },
  ];
  const {
    error: getRequestError,
    getAllEuroChallengeRequests,
    isLoading: getRequestLoading,
    result,
  } = useGetAllEuroChallengeRequests();

  const {
    error: deleteError,
    deleteEuroChallengeRequest,
    isLoading: deleteLoading,
    result: deleteResult,
  } = useDeleteEuroChallengeRequest();

  const {
    acceptEuroChallengeRequest,
    isLoading: acceptLoading,
    error: acceptError,
    result: acceptResult,
  } = useAcceptEuroChallengeRequest();
  const {
    rejectEuroChallengeRequest,
    isLoading: rejectLoading,
    error: rejectError,
    result: rejectResult,
  } = useRejectEuroChallengeRequest();

  useEffect(() => {
    getAllEuroChallengeRequests();
  }, [deleteResult, paginationModel]);

  const rows = result?.data?.rows?.map((row: RowType) => {
    return {
      ...row,
      id: row.eurochallenge_request__id,
    };
  });

  return (
    <>
      <div className="overflow-auto h-full w-full">
        <DataGrid
          rows={rows || []}
          columns={columns}
          loading={getRequestLoading}
          autoHeight
          paginationMode="server"
          paginationModel={paginationModel}
          rowCount={result?.data?.total_rows}
          pageSizeOptions={[5, 10, 15, 20]}
          onPaginationModelChange={setPaginationModel}
          hideFooterPagination
          getRowClassName={(params) =>
            `${
              params.row.status === "1"
                ? classes.accept
                : params.row.status === "0"
                ? classes.reject
                : ""
            }`
          }
        />
      </div>
      <DeleteModal
        isOpen={deleteOptions.showConfirmDelete}
        setDeleteOptions={setDeleteOptions}
        onConfirm={() => deleteRequestAction(deleteOptions)}
      ></DeleteModal>
      <DeleteModal
        isOpen={acceptOptions.showConfirmAccept}
        setDeleteOptions={setAcceptOptions}
        onConfirm={() => acceptRequestAction(acceptOptions)}
        title="Confirm Accept"
        content="Are you sure to accept ?"
      ></DeleteModal>
      <DeleteModal
        isOpen={rejectOptions.showConfirmReject}
        setDeleteOptions={setRejectOptions}
        onConfirm={() => rejectRequestAction(rejectOptions)}
        title="Confirm Reject"
        content="Are you sure to reject ?"
      ></DeleteModal>
    </>
  );
};

export default AllEuroChallengeRequestsTable;
