import { useState } from "react";
import axios from "axios";
import adminAPI from "../../constants/admin-API";
import useToken from "../useToken";
interface RejectType {
  requestId: string;
}
const useRejectWinterCupRequest = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [result, setResult] = useState<any>(null);
  const token = useToken();
  const rejectWinterCupRequest = async (
    { requestId }: RejectType,
    callback?: any
  ) => {
    const formData = new FormData();
    formData.append("wintercup_request__id", requestId);
    try {
      setIsLoading(true);
      await axios
        .post(adminAPI.rejectRequest.winterCup, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          setIsLoading(false);
          setResult(data);
          if (callback) {
            callback(data);
          }
          return data;
        });
    } catch (e: any) {
      setIsLoading(false);
      console.log(e);
      setError(e);
      return e;
    }
  };

  return {
    rejectWinterCupRequest,
    result,
    isLoading,
    error,
  };
};
export default useRejectWinterCupRequest;
