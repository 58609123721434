import { useEffect, useState } from "react";
import AllGoldenSportsRequestsTable from "./golden-sports/allRequestsTable";
import AllSommerCupRequestsTable from "./sommer-cup/allRequestsTable";
import AllEuroChallengeRequestsTable from "./euro-challenge/allRequestsTable";
import AllWinterCupRequestsTable from "./winter-cup/allRequestsTable";
import useCheckToken from "../../hooks/useCheckToken";
import TabsList from "../../components/Tabs/TabsList";
import AllKontinentalCupRequestsTable from "./kontinental-cup/allRequestsTable";
import KontinentalLogo from "./../../logos/kontinental-logo.svg";
import EuroChallengeLogo from "./../../logos/euro-challenge-logo.svg";
import SommerCupLogo from "./../../logos/sommer-cup-logo.svg";
import WinterCupLogo from "./../../logos/winter-cup-logo.svg";
import GoldenSportsLogo from "./../../logos/golden-sports-logo.svg";
import austriaTourLogo from "./../../logos/austria-tour.png";
import AllAustriaTourRequestsTable from "./austria-tour/allRequestsTable";

const Home = () => {
  const checkToken = useCheckToken();
  useEffect(() => {
    checkToken();
  }, []);
  return (
    <>
      <TabsList
        tabsContainerSx={{
          backgroundColor: "#f9f9f9",
        }}
        tabIndicatorSx={{
          backgroundColor: "black",
        }}
        tabSx={{
          color: "black",
          "&.Mui-selected": {
            color: "black",
            fontWeight: "700",
          },
        }}
        tabs={[
          {
            children: <AllGoldenSportsRequestsTable />,
            title: "Golden Sports",
            logo: GoldenSportsLogo,
          },
          {
            children: <AllSommerCupRequestsTable />,
            title: "Sommer Cup",
            logo: SommerCupLogo,
          },
          {
            children: <AllEuroChallengeRequestsTable />,
            title: "EuroChallnege",
            logo: EuroChallengeLogo,
          },
          {
            children: <AllKontinentalCupRequestsTable />,
            title: "KontinentalCup",
            logo: KontinentalLogo,
          },
          {
            children: <AllWinterCupRequestsTable />,
            title: "WinterCup",
            logo: WinterCupLogo,
          },
          {
            children: <AllAustriaTourRequestsTable />,
            title: "AustriaTour",
            logo: austriaTourLogo,
          },
        ]}
        initialValue={0}
      ></TabsList>
    </>
  );
};
export default Home;
