import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface Props {
  isOpen: boolean;
  setDeleteOptions: Dispatch<SetStateAction<any>>;
  onConfirm?: any;
  onCancle?: any;
  content?: string;
  title?: string;
}

const DeleteModal = ({
  isOpen,
  onCancle,
  onConfirm,
  setDeleteOptions,
  content="Are you sure to delete ?",
  title = "Confirm Delete",
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setDeleteOptions({
          showConfirmDelete: false,
        });
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogContent>
        <Button
          onClick={() => {
            setDeleteOptions({
              showConfirmDelete: false,
            });
            if (onConfirm) {
              onConfirm();
            }
          }}
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            setDeleteOptions({
              showConfirmDelete: false,
            });
            if (onCancle) {
              onCancle();
            }
          }}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteModal;
