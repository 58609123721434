import './App.css';
import AdminRouter from './router';

function App() {
  return (
    <AdminRouter />
  );
}

export default App;
